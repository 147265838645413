// Header section start
.header,
.mainsection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: linear-gradient(to right, #676ff7, #3341c3);
  padding-right: 27px;
}
.mainsection {
  background: #fff;
  padding-right: 8px;
  border: 1px solid #d3d3d3;
  border-left: none;
  border-right: none;
}
.nav_logo_link {
  height: 63px;
  width: 232px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.header_text {
  font-size: 16px;
  color: #fff;
  border-right: 2px solid rgba(255, 255, 255, 0.4);
  padding: 7px 17px 7px 0;
}
img.logo {
  width: 83px;
}
.logout_btn {
  background-color: #fff;
  padding: 10px;
  border-radius: 7px;
  color: #3341c3;
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
}
.menu_btn {
  min-height: 63px;
  width: 232px;
  background: #6664d7 !important;
  color: #fff;
  margin-right: 20px;
  border-radius: 0px;
  border: none;
  font-weight: 700;
  padding: 0 19px 0 46px;
  text-align: left;
  border-radius: 0 !important;
  &:focus {
    box-shadow: none !important;
  }
  .icon_cstm {
    color: white !important;
  }
  .p-button-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
  }
}

.sidenav_menu {
  background: #6664d7 !important;
  top: -1px !important;
  bottom: 0;
  box-shadow: none;
  color: #fff;
  border-radius: 0px !important;
  padding: 0 20px;
  margin-top: 115px !important;
  z-index: 9999 !important;
  position: absolute !important ;
  width: 232px !important;
  .p-menu-list {
    margin-left: 56px;
    margin-top: 10px;
  }

  .p-menuitem {
    font-size: 16px;
    display: block;
    text-decoration: none;
    transition: 0.3s ease;
    background: rgba(1, 1, 30, 0.14);
    border-right: 4px solid #fff;
    margin-bottom: 10px;
    width: 188px;
    height: 40px;
    margin-left: -38px;
    .p-menuitem-icon,
    .p-menuitem-text {
      color: white !important;
    }
    &:hover {
      .p-menuitem-icon,
      .p-menuitem-text {
        color: rgb(75, 72, 72) !important;
      }
    }
  }
}
.header-lower {
  display: flex;
}
.header_info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .tariff_label {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    color: #1c2024;
    margin-left: 24px;
    margin-right: 16px;
  }
  .open_tariff {
    width: 128px;
    height: 42px;
    left: 386px;
    top: 71px;
    background: rgba(224, 232, 244, 0.6);
    border-radius: 4px;
    border: none;
    b {
      user-select: all;
      margin-left: 14px;
    }
  }
}
.header_button {
  .file_upload_btn {
    background: #4f46e5;
    height: 32px;
    width: 141px;
    &:focus {
      box-shadow: none;
    }
  }
}
.finish_btn {
  width: 101px;
  height: 32px;
  font-size: 14px !important;
}
.save_as_draft_btn {
  width: 131px !important;
  height: 32px;
  font-weight: 700;
  font-size: 14px !important;
  background: #56c48e !important;
  border: 1px solid #56c48e !important;
  margin-right: 16px !important;
}
.download_btn {
  color: #2e2beb !important;
  height: 32px;
  width: 115px;
  margin-right: 16px !important;
  background-color: white !important;
  border: none !important;
  font-weight: 600;
  font-size: 16px !important;
  .download_image {
    margin-right: 7px !important;
    margin-left: -10px !important;
  }
}
.provider_code {
  margin-left: 41px;
  width: 240px !important;
  strong {
    text-decoration: underline;
    margin-left: 8px;
  }
  img {
    margin-left: 8px;
    position: absolute;
  }
}
.start_date_cale {
  width: 140px;
  height: 34px;
  margin-left: 17px;
  border-right: none !important;
  .p-datepicker-trigger {
    width: 24px !important;
    color: black !important;
    background: white !important;
    border-left: none !important;
    border: 1px solid rgb(206, 212, 218);
    &:hover {
      border-left: none !important;
      border: 1px solid rgb(206, 212, 218) !important;
    }
  }
  .p-inputtext {
    border-right: none !important;
  }
}
// Header section end

// Home Datatable started
.datatable_section {
  margin: 8px !important;
  .p-datatable
    > .p-datatable-wrapper
    > .p-datatable-table
    > .p-datatable-tbody
    > tr {
    .p-cell-editing {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      color: red !important;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #343a40;
  border: none !important;
  border-width: 1px 0 1px 0;
  padding: 0 !important;
  font-weight: 700;
}
.datatable_header {
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #d3d3d3;
  background: #ffffff;
  border-radius: 4px;
  height: 56px;
  .remove_filter{
    font-size: 20px;
    margin-top: 7px;
    margin-left: 10px !important;
    color: red;
    &:hover{
      cursor: pointer;
    }
  }
  .search_input {
    background: #f1f4f8;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #9ba5ae;
    border: 1px solid #d3d3d3 !important;
    border-right: none !important;
    width: 650px !important;
    height: 36px;
    margin-bottom: 10px;
    &:focus {
      box-shadow: none !important;
    }
  }
  .search_btn {
    background: #f1f4f8 !important;
    border: solid 1px #d3d3d3;
    border-left: none;
    color: #a8aab7;
    height: 36px;
    &:focus {
      box-shadow: none !important;
    }
    &:hover {
      border: solid 1px #d3d3d3 !important;
      border-left: none !important;
    }
  }
  .filter_label {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #1c2024;
    margin-left: 24px;
    margin-top: 5px;
  }
  .filter_selection {
    background: #f1f4f8;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #9ba5ae;
    height: 36px !important;
    width: 160px !important;
    margin-left: 11px;
    &:hover {
      border: solid 1px #d3d3d3 !important;
    }
    &:focus {
      border: solid 1px #d3d3d3 !important;
      box-shadow: none !important;
    }
    .p-placeholder{
      font-size: 14px !important;
      padding-top: 6px !important;
    }
  }
  .refresh_button {
    height: 36px;
    background: white;
    border: none;
    margin-left: 10px !important;
    &:hover {
      background: white !important;
      border: none;
    }
    &:focus {
      background: white !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.datatable_calendars {
  height: 36px;
  width: 174px;
  margin-left: 24px;
  .p-inputtext {
    border: 1px solid #dfe3e9;
    background: #f1f4f8;
    border-right: none;
    font-weight: normal;
    font-size: 16px;
    font-style: italic;
    line-height: 35px;
    color: #9ba5ae;
    max-width: 196px;
    min-width: 40px;
    padding-top: 3px;
    padding-bottom: 3px;
    &:focus {
      box-shadow: none !important;
    }
    &:hover {
      border: 1px solid #dfe3e9 !important;
      box-shadow: none !important;
    }
  }

  &:placeholder {
    font-style: italic;
    color: #9ba5ae;
  }
  .p-datepicker-trigger {
    background: #f1f4f8;
    border: 1px solid #f1f4f8;
    border-left: none;
    padding-top: 3px;
    padding-bottom: 3px;
    border: solid 1px #dfe3e9;
    border-left: none;
    .pi-calendar-plus {
      color: #1613d1;
      background: #3365a7;
    }
  }
}

.tarrif_home_table {
  .p-datatable-wrapper {
    border-radius: 4px;
    border: 1px solid #d3d3d3;
  }
  .p-datatable-thead {
    th {
      height: initial !important;
    }
    .p-column-title {
      font-weight: bold;
      font-size: 12px;
      line-height: 26px;
      color: #1c2024;
    }
    .p-column-header-content {
      .pi {
        font-size: 13px;
      }
    }
  }
  .p-datatable-tbody {
    td {
      font-size: 12px;
      line-height: 16px;
      color: #1c2024;
    }
  }
}
.status-open {
  padding: 2px 11px;
  background: #d4f5e8;
  border-radius: 13px;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: #1abe7f;
}

.status-verified {
  padding: 2px 11px;
  background: #d4f5e8;
  border-radius: 13px;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: #1abe7f;
}
.status-received {
  padding: 2px 11px;
  background: #ebebfa;
  border-radius: 13px;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: blue;
}
// Home Datatable end

// Traiff studio page start

// Popup css started
.report_gen_popup {
  &.p-dialog .p-dialog-content {
    padding: 0;
  }
  .p-dialog-header {
    height: 48px !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4846dc;
    .p-dialog-header-icons {
      margin-top: -20px !important;
    }
  }
  .popup_header {
    display: flex;
    align-items: center;
    img {
      margin-right: 20px;
      transform: rotate(0deg);
      animation: spin_load 1s linear infinite;
    }
    @keyframes spin_load {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1c2024;
    margin-top: 16px;
    margin-left: 35px;
  }
  .replace_btn {
    width: 263px;
    height: 32px;
    background-color: #4d5cdd;
    text-align: center;
  }
  .cancel_bth {
    width: 101px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #e15554;
    color: #e15554;
    margin-right: 10px;
    &:hover {
      background-color: #fff !important;
      border: 1px solid #e15554 !important;
      color: #e15554 !important;
    }
  }
  .btn_div {
    text-align: end;
    margin-top: 40px;
  }

  .p-dialog-header-icons {
    margin-top: 10px !important;
  }
}
// Popup css end

.add_column_popup{

  .p-dialog-header{
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    border-bottom: 1px dotted !important;
    color: rgb(67,86,208) !important;
  }
  .dwopdown_section{
    display: flex;
    margin-top: 20px;
  }
  .p-dropdown{
    width: 250px;
    height: 45px;
    margin-left: 15px;
    margin-top: 10px;
  }
  .replace_btn {
    width: 263px;
    height: 32px;
    background-color: #4d5cdd;
    text-align: center;
  }
  .cancel_bth {
    width: 101px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #e15554;
    color: #e15554;
    margin-right: 10px;
    &:hover {
      background-color: #fff !important;
      border: 1px solid #e15554 !important;
      color: #e15554 !important;
    }
  }
}

.traiff_main {
  margin: 9px !important;
  .traiff_col {
    height: 660px;
    background: #ffffff;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    .action_section {
      height: 52px;
      left: 680px;
      top: 130px;
      display: flex;
      background: #ffffff;
      border: 1px solid #d3d3d3;
      border-radius: 4px;
      justify-content: space-between;

      .action_icon {
        margin-top: 15px;
        margin-left: 22px;
        &:hover {
          cursor: pointer;
        }
      }
      .add_new_column{
        margin-top: 9px !important;
        margin-left: 10px !important;
      }
      .row_col_input {
        display: flex;
        margin-left: 12px;
        .row_input_col{
          width: auto !important;
        }
        .row_input_text{
          margin-top: 17px;
          font-size: 15px;
          font-weight: 500;
        }
        .col_input {
          margin-top: 10px;
          .p-inputtext {
            width: 64px !important;
            height: 32px !important;
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            &:hover,
            &:focus {
              box-shadow: none !important;
              border-color: #ced4da !important;
            }
          }
        }
        .row_input {
          margin-left: 7px;
          margin-top: 10px;
          .p-inputtext {
            width: 64px !important;
            height: 32px !important;
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            &:hover,
            &:focus {
              box-shadow: none !important;
              border-color: #ced4da !important;
            }
          }
        }
        .icon {
          height: 32px !important;
          width: 30px;
          border: 1px solid #d8dde1;
          // border-radius: 4px;
          margin-top: 10px;
          border-left: none;
          padding-top: 7px;
          padding-right: 6px;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          margin-right: 20px;
          &::before{
            &:hover{
              cursor: pointer;
            }
          }
        }
        div {
          display: flex;
        }
      }
      .speciality {
        font-weight: 700;
        font-size: 14px;
        line-height: 26px;
        text-decoration-line: underline;
        color: #6664d7;
        text-align: center;
      }
    }
  }
}

.qc_table {
  max-height: 580px;
  overflow: scroll;
  margin-top: 10px;
  .new_row{
    border: 2px solid green;
  }
  .datatable_qc {
    background: #ffffff;
    border: 1px solid #d3d3d3;
    box-sizing: border-box;
    border-radius: 4px;
    .p-datatable-thead > tr > th {
      padding: 1.429em 0.857em;
      white-space: nowrap;
    }
    .p-datatable-header {
      background: #fff;
      padding: 10px 0 !important;
      & > div {
        justify-content: flex-start !important;
      }
      p {
        margin-bottom: 0px;
      }
    }
    .p-column-title {
      font-size: 12px;
      line-height: 26px;
      font-weight: 700;
      color: #1c2024;
      /*padding: ;*/
    }
    .p-sortable-column-icon {
      font-size: 10px;
    }
    .p-datatable-thead {
      th {
        background: #fff;
        &.s_no
        {
           min-width: 70px !important;
           width: auto !important;
        }
        &.action_data
        {
          min-width: 90px !important;
          width: auto !important;
        }
      }
    }
    .p-datatable-tbody {
      td {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #1c2024;
        .p-inputtext {
          border: none;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #1c2024;
          background: #f1f4f8;
          width: 100%;
          padding: 6px 10px;
        }
        &.s_no
        {
           min-width: 70px !important;
           width: auto !important;
        }
        &.action_data
        {
          min-width: 90px !important;
          width: auto !important;
        }
      }
    }
  }
  .header_style {
    font-weight: 700;
    font-size: 12px;
    line-height: 26px;
    color: #1c2024;
  }
}
.action_button{
  display: flex;
  .action_btn{
    background: transparent !important;
    border-color: transparent !important;
    .p-button-icon
    {
      color: rgb(28, 32, 36);
    }
    &:focus
    {
      box-shadow: none !important;
    }
  }
  .cancel_btn{
    &:hover{
      color: red !important;
    }
  }
}

// TRaiff studio page end
.modal_text {
  font-size: 14px;
  font-weight: 400;
  margin: 0px 0;
}
.modal_texthead {
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 8px;
  border-bottom: 1px dashed #ececec;
}
.modal_dropdown {
  &.p-dropdown {
    width: 146px;
    border: 1px solid #dfe3e9;
  }
  .p-dropdown-label {
    font-size: 14px;
    color: #1c2024 !important;
    padding: 0.42em 0.75em;
    background: rgba(223, 227, 233, 0.25) !important;
    border-radius: 4px;
  }
  .p-dropdown-trigger {
    width: 1.5em;
    background: rgba(223, 227, 233, 0.25) !important;
    span {
      color: #14171a;
      font-size: 10px;
    }
  }
}
.login-section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;
  .login_head {
    color: #2e2beb;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 37px;
  }
  .logo_div{
    text-align: center;
    margin-top: 10px;
  }
  .logo_img{
    height: 50px;
    margin-bottom: 10px;
  }
  .inner_wrapper {
    padding: 30px;
    padding-top: 0px;
  }
}
.inner-login {
  margin: auto;
  max-width: 900px;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 10px;
}
.login_field {
  width: 100%;
  background: #fff;
  border: 1px solid #b0b0b0 !important;
  border-radius: 7px;
  margin-bottom: 10px !important;
}

.login_btn.p-button {
  width: 100%;
  display: block;
  font-size: 15px;
  margin-top: 20px;
  padding: 10px 0;
}
.login_img {
  width: 237px;
}
.login_head {
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 20px;
}
.login_img_wrapper {
  padding: 20px;
  text-align: center;
}
.report-table {
  width: 100%;
  th {
    text-align: left;
  }
  td {
    padding: 10px 0;
  }
}

// FabricImages Component Starts
.fabricSetion {
  border: 1px solid black;
  padding: 1rem;
  width: 900px;
  max-width: 100%;
  height: 100%;
  overflow: auto;

  .fabTop {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .fabHeader {
      display: flex;
      padding-left: 20px;
      padding-right: 20px;
      align-items: center;
      border: 1px solid rgba(72, 70, 220, 0.4);
      border-radius: 4px;
      .uploPic {
        width: 27px;
      }
      .lowFile {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        color: #343a40;
      }
    }

    .pageCurrent {
      display: flex;
      margin-left: 10px;
      .arrow_imgbx {
        cursor: pointer;
      }
    }
  }
  .konvaImages {
    width: 800px;
    height: 900px;
    margin-top: 10px;
  }
}

.embd_pdf {
  height: 100%;
  width: 100%;
}

// FabricImages Component Ends.
.white-nowrap {
  white-space: nowrap;
}

//Loading Screen
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba($color: #fff, $alpha: 0.7);
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes innerloading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.innerloading div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: innerloading linear 1s infinite;
  background: #3341c3;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}
.innerloading div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #3341c3;
}
.innerloading div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #3341c3;
}
.innerloading div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #3341c3;
}
.innerloading div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #3341c3;
}
.innerloading div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #3341c3;
}
.innerloading div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #3341c3;
}
.innerloading div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #3341c3;
}
.innerloading div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #3341c3;
}
.innerloading div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #3341c3;
}
.innerloading div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #3341c3;
}
.innerloading div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #3341c3;
}
.innerloading div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #3341c3;
}
.loadingio-spinner-spinner-nlbm9ig4fhm {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.innerloading {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.innerloading div {
  box-sizing: content-box;
}
// Loading Screen
