.file_upload_popup {
  text-align: center;
  display: flex;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #5d66ec;
  color: white;
  border-radius: 10px;
}

.file-preview {
  font-size: 20px;
  font-weight: 600;
  color: blue;
  margin-top: 5px;
  margin-left: 20px;
  .delete_selected_image {
    color: red;
    margin-left: 15px;
    &:hover {
      cursor: pointer;
    }
  }
}
.upload_file {
  padding: 8px 15px !important;
}
.cancel_btn {
  padding: 8px 15px !important;
  background-color: #fff;
  color: #e15554 !important;
  border: 1px solid #e15554 !important;
  margin-right: 30px !important;
}
.p-dialog-header {
  padding: 20px !important;
}
.popup_input_box {
  margin-top: 5px;
  height: 35px;
  width: 220px;
}

.confirmation_popup {
  .cancel_bth {
    width: 101px;
    height: 32px;
    background-color: #fff !important;
    border: 1px solid #e15554 !important;
    color: #e15554 !important;
    margin-right: 10px;
    &:hover {
      background-color: #fff !important;
      border: 1px solid #e15554 !important;
      color: #e15554 !important;
    }
  }
  .btn_div {
    text-align: end;
    margin-top: 40px;
  }

  .heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1c2024;
    margin-top: 16px;
    margin-left: 35px;
  }
  .replace_btn {
    width: 263px;
    height: 32px;
    background-color: #4d5cdd;
    text-align: center;
  }
}

// 404 page design
.page_not_found {
  height: 100vh;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-content: center;
  .info {
    margin: auto !important;
  }
}

// 404 page design
